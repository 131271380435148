import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
import JSONFormats from "../../Assets/Formats.json";
import style from "./MainContent.module.css";
import Card from "../Card/Card";
import Button from "react-bootstrap/Button";
import CategorySelector from "../CategorySelector/CategorySelector";

function MainContent() {
  const dispatch = useDispatch();
  const adFormat = useSelector((state) => state.adFormat);
  const category = useSelector((state) => state.category);

  const initialTrigger = useRef(false);

  const [renderedData, setRenderedData] = useState([]);

  // useEffect(() => {
  //   console.log(renderedData.length, adFormat.data.length);
  // }, [renderedData.length]);

  useEffect(() => {
    if (!initialTrigger.current) {
      dispatch(actions.changeAdFormat(JSONFormats.richMedias));
      initialTrigger.current = true;
    } else {
      setRenderedData(
        adFormat.name === "Rich Media" && category
          ? adFormat.data[category].slice(0, 6)
          : adFormat.name === "Rich Media"
          ? adFormat.data.allContent.slice(0, 6)
          : adFormat.data.slice(0, 6)
      );
    }
  }, [adFormat, category]);

  const addNextQueue = () => {
    const nextQueue = adFormat.data[category].slice(
      renderedData.length + 1,
      renderedData.length + 7
    );
    setRenderedData([...renderedData, ...nextQueue]);
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.titleContainer}>
          <h1 className={style.title}>{adFormat?.name}</h1>
          {adFormat.name === "Rich Media" && (
            <CategorySelector></CategorySelector>
          )}
        </div>

        <ul className={style.adsContainer}>
          {renderedData?.map((ad, index, array) => (
            <Card
              name={adFormat.name}
              key={index}
              link={ad.link}
              height={ad.height}
              title={ad.title}
              sizes={ad.sizes}
              observations={ad.observations}
              cards={array}
            ></Card>
          ))}
        </ul>
        {adFormat.data &&
        renderedData.length < adFormat?.data[category]?.length - 1 ? (
          <div className={style.moreButtonCont}>
            <Button onClick={() => addNextQueue()} className={style.moreButton}>
              Cargar más
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

export default MainContent;
