export const CHANGE_AD_FORMAT = "CHANGE_AD_FORMAT";
export const FILL_SPECS = "FILL_SPECS";
export const OPEN_SPECS = "OPEN_SPECS";
export const FILTER_BY_CATEGORY = "FILTER_BY_CATEGORY";

export const filterByCategory = (category) => {
  return { type: FILTER_BY_CATEGORY, payload: category };
};

export const changeAdFormat = (adFormat) => {
  return { type: CHANGE_AD_FORMAT, payload: adFormat };
};

export const fillSpecs = (specs) => {
  return { type: FILL_SPECS, payload: specs };
};

export const openSpecs = () => {
  return { type: OPEN_SPECS };
};
