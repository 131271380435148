import React from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
import JSONFormats from "../../Assets/Formats.json";

import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Logo from "../../Assets/369_b_logo.webp";
import style from "./NavBar.module.css";

function NavBar() {
  const dispatch = useDispatch();

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="#home" style={{ maxWidth: "200px" }}>
          <img className={style.logo} src={Logo} alt="Logo 369" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto fs-md-5">
            <Nav.Link
              className={`${style.format}`}
              onClick={() =>
                dispatch(actions.changeAdFormat(JSONFormats.richMedias))
              }
              href="#home"
            >
              Rich Media
            </Nav.Link>
            <Nav.Link
              className={`${style.format}`}
              onClick={() =>
                dispatch(actions.changeAdFormat(JSONFormats.banners))
              }
              href="#link"
            >
              Banners
            </Nav.Link>
            <Nav.Link
              className={`${style.format}`}
              onClick={() =>
                dispatch(actions.changeAdFormat(JSONFormats.DCOBanners))
              }
              href="#link"
            >
              DCO Banners
            </Nav.Link>
            <Nav.Link
              className={`${style.format}`}
              onClick={() =>
                dispatch(actions.changeAdFormat(JSONFormats.native))
              }
              href="#link"
            >
              Native
            </Nav.Link>
            <Nav.Link
              className={`${style.format}`}
              onClick={() =>
                dispatch(actions.changeAdFormat(JSONFormats.video))
              }
              href="#link"
            >
              Video
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
