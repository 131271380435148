import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import style from "./Footer.module.css";
import Logo from "../../Assets/369_b_logo.webp";

function Footer() {
  return (
    <footer className="mt-lg-5">
      <div className="container">
        <div
          className={`row ${style.contactFooter} py-3 text-center fs-6 fs-md-5`}
        >
          <div className="col-12 col-md-6">
            <i className="fa-regular fa-envelope me-2"></i>
            <a href="mailto:comercial@369.ad">comercial@369.ad</a>
          </div>
          <div className="col-12 col-md-6">
            <i className="fa-solid fa-house me-2"></i>
            <a href="https://369.ad">369.ad</a>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center">
            <img src={Logo} alt="Logo 369" className={style.logoFooter} />
            <p className={style.copyright}>
              © Copyright 2024 369 Crafting AdTech
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
