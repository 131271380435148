import "./App.css";
// import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./Components/Navbar/NavBar";
import MainContent from "./Components/MainContent/MainContent";
import Footer from "./Components/Footer/Footer";
import Specs from "./Components/Specs/Specs";

function App() {
  return (
    <div className="App">
      <Specs></Specs>
      <div className="container">
        <NavBar></NavBar>
        <MainContent></MainContent>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
