import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
import style from "./Card.module.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Button from "react-bootstrap/Button";
import Loader from "../../Assets/rolling.svg";

function Card({ name, link, height, title, observations, sizes, cards }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  console.log(cards);

  const handleLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const specsHandler = (specs) => {
    dispatch(actions.fillSpecs(specs));
    dispatch(actions.openSpecs());
  };

  return (
    <li className={style.ad} data-aos="fade-up">
      {loading && (
        <div className={style.loaderContainer}>
          <img src={Loader} alt="" />
        </div>
      )}
      {name === "Rich Media" ? (
        <iframe
          loading="lazy"
          sandbox="allow-scripts allow-same-origin"
          title={title}
          src={link}
          width="320px"
          height={height ? height : "480px"}
          onLoad={handleLoad}
        ></iframe>
      ) : (
        <img className={style.adContent} src={link} alt={title} />
      )}

      <h3 className={style.adTitle}>{title}</h3>
      <Button
        onClick={() => specsHandler({ title, sizes, observations })}
        className={style.specsButton}
      >
        + ESPECIFICACIONES
      </Button>
    </li>
  );
}

export default Card;

//! AGREGAR A LOADER QUE TENGA EL MISMO TAMAÑO QUE LOS IFRAMES Y UN BACKGROUND COLOR BLANCO PARA TAPAR EL RICH MEDIA Y TRANSICION
