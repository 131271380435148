const initialState = {
  adFormat: [],
  specs: {},
  openedSpecs: false,
  category: "allContent",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_AD_FORMAT":
      return { ...state, adFormat: action.payload };
    case "FILL_SPECS":
      return { ...state, specs: action.payload };
    case "FILTER_BY_CATEGORY":
      return {
        ...state,
        category: action.payload,
      };
    case "OPEN_SPECS":
      return { ...state, openedSpecs: !state.openedSpecs };
    default:
      return { ...state };
  }
}
