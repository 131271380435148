import React, { useEffect, useRef } from "react";
import style from "./Specs.module.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";

import close from "../../Assets/close.png";

function Specs() {
  const dispatch = useDispatch();

  const specsRef = useRef();
  const closeRef = useRef();

  const globalSpecs = useSelector((state) => state.specs);
  const openedSpecs = useSelector((state) => state.openedSpecs);

  //   useEffect(() => {}, [globalSpecs]);

  const outsideCloseHandler = (e) => {
    if (!specsRef.current.contains(e.target)) {
      dispatch(actions.openSpecs());
    }
  };

  const closeHandler = () => {
    dispatch(actions.openSpecs());
  };

  return (
    <div
      onClick={(e) => outsideCloseHandler(e)}
      style={{
        opacity: `${openedSpecs ? 1 : 0}`,
        zIndex: `${openedSpecs ? 1 : -1}`,
      }}
      className={style.outterLayer}
    >
      <div
        ref={specsRef}
        className={style.specsContainer}
        style={{
          opacity: `${openedSpecs ? 1 : 0}`,
          zIndex: `${openedSpecs ? 1 : -1}`,
          top: `${openedSpecs ? "50%" : "30%"}`,
        }}
      >
        <img
          onClick={() => closeHandler()}
          ref={closeRef}
          className={style.close}
          src={close}
          alt="Close Icon"
        />
        <h1>{globalSpecs.title}</h1>

        {globalSpecs.sizes && (
          <span>
            <h3>Tamaños:</h3>
            <p>{globalSpecs.sizes}</p>
          </span>
        )}

        {globalSpecs.type && (
          <span>
            <h3>Tipo:</h3>
            <p>{globalSpecs.type}</p>
          </span>
        )}

        {globalSpecs.ratio && (
          <span>
            <h3>Ratio:</h3>
            <p>{globalSpecs.ratio}</p>
          </span>
        )}

        {globalSpecs.duration && (
          <span>
            <h3>Duración:</h3>
            <p>{globalSpecs.duration}</p>
          </span>
        )}

        {globalSpecs.skippableAds && (
          <span>
            <h3>Skippable Ads:</h3>
            <p>{globalSpecs.skippableAds}</p>
          </span>
        )}

        {globalSpecs.NonSkippableAds && (
          <span>
            <h3>Non-Skippable Ads:</h3>
            <p>{globalSpecs.NonSkippableAds}</p>
          </span>
        )}

        {globalSpecs.BumperAds && (
          <span>
            <h3>Bumper Ads:</h3>
            <p>{globalSpecs.BumperAds}</p>
          </span>
        )}

        {globalSpecs.observations ? (
          <span>
            <h3>Observaciones:</h3>
            <p>{globalSpecs.observations}</p>
          </span>
        ) : (
          <h3>Consultar</h3>
        )}
      </div>
    </div>
  );
}

export default Specs;
